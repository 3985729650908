/* globals must be listed below to pass JSHint */
/* globals console, getRequestPath, searchListForItem, showAlert, renewServerSession, requestTimeout, refreshAllocationPage,
 showAjaxLoading, hideAjaxLoading, sortByProperty, searchList, isNumeric, searchArrayForObject, clearAuthorisation,
 getBarcodeMapping, getIncidentCodes, sendTransactions, logOut, getCurrentPage, isTimeOutOfSync, removeAllocations,
  saveAuthorisationTransaction, doesProgramSendTransactions, CryptoJS, login, downloadStandingData, getAllocationListToDownload,
  downloadMultipleAllocations, downloadTrailerData, isScannerTimeCorrect, offlineLoginCleanup, showIncorrectTimeMessage, areTrailersInUse,
  updateDownloadedContainers, areContainersInUse, areAllAllocationsDownloaded, clearScannerStartLocations, updateAllocations, isNumericAndNotZero,
 areDespatchesInUse, getScannerLocationCode, updateDownloadedDespatches, getConfig, updateCapacities */
// This holds the timeout function which will log out the scanner when inactive.
var sessionTimeout;

var applicationState = {
  networkOnline: false,
  triedToRenewServerSession: false // This tracks if a renewal of the server session has tried and failed.
};

/* New section of functions to get local storage variables for the scanner at runtime
*  If we can reference these (&update old code) in new code we can then move to SS later and rename as needed */

function getScannerId() {
	"use strict";
	return parseInt(localStorage.getVariable("scannerid"));
}

function getScannerCompany() {
	"use strict";
	if (localStorage.getItem("settings/scannerCompany")) {
		return parseInt(localStorage.getVariable("settings/scannerCompany"));
	} else {
		return parseInt(localStorage.getVariable("scannercoyno"));
	}
}

function getActivityTime() {
	"use strict";
	return parseInt(sessionStorage.getVariable("runtime/activity"));
}

function setActivityTime() {
	"use strict";
	sessionStorage.setVariable("runtime/activity", new Date().getTime(), false);
}

function getUserCompany() {
	"use strict";
	return parseInt(sessionStorage.getVariable("runtime/userCompany"));
}

/**
 * @description Returns the network state of the application, which will have been set when pinging the server.
 * This would happen when a page loads or the Application Status Dialog is opened.
 * @return {boolean} True if the network is thought to be online.
 */
function getApplicationNetworkState() {
  "use strict";
  // In the future with Android we may be able to call Navigator.onLine or listen to online/offline events.
  return applicationState.networkOnline;
}

/**
 * @description gets users stored on the scanner, used for offline use and other info.
 */
function getScannerUsers() {
	"use strict";
	return (localStorage.getObject("data/users"))? localStorage.getObject("data/users"): localStorage.getObject("dramscanusers");
}

/**
 * @description Find the user profile of the currently logged in user and return that as an object
 * @returns {userProfile}
 */
function getCurrentUser() {
    "use strict";
    var dramscanUsers = getScannerUsers();
    var currentUser = searchListForItem(dramscanUsers,"id",getSessionUserId());
    return dramscanUsers[currentUser];
}

/**
 * @description Creates a local session
 * @param {String} userid
 * @param {String} username
 * @param {String} coyno
 */
function createSession(userid, username, coyno) {
  "use strict";
  sessionStorage.setVariable("runtime/userId", userid, false);
  sessionStorage.setVariable("runtime/username", username, false);
  localStorage.setVariable("runtime/lastUsername",username, false);
	sessionStorage.setVariable("runtime/userCompany",coyno, false);
  setActivityTime();
}

/**
 * @description Clears storage of any session information and temporary variables.
 * @param {Boolean} expired If the session expired
 */
function clearSession(expired) {
  "use strict";
  localStorage.removeItem("userid");
  localStorage.removeItem("username");
  localStorage.removeItem("activitytime"); // To be removed eventually
  localStorage.removeItem("currentmenu");
  localStorage.removeItem("coyno");
  localStorage.removeItem("pickingallocation");
  localStorage.removeItem("pickingcounter");
	localStorage.removeItem("lastusername");

  clearScanParameters();
  sessionStorage.clear();
  if(expired) sessionStorage.setItem("logoutMessage", "Your session has timed out. Please log in to continue.");
}

/**
 * @description Clears scanning parameters when opening a new function
 */
function clearScanParameters() {
  //todo these are no longer used but remain here to clear old versions of DS for now.
  "use strict";
  localStorage.removeItem("scanallocation");
  localStorage.removeItem("scanlocation");
  localStorage.removeItem("scanwarehouse");
  localStorage.removeItem("scanbay");
  localStorage.removeItem("scanrack");
  localStorage.removeItem("scanlevel");
  localStorage.removeItem("scandirection");
  localStorage.removeItem("scanCounter");
  localStorage.removeItem("BlankAllocation"); // old way of tracking trailer load without allocation.
  //todo clearing old code storage
  localStorage.removeItem("transactions");
	localStorage.removeItem("locationcode");
	localStorage.removeItem("locationname");
	localStorage.removeItem("lastmenu");
  //todo
  localStorage.removeItem("scanTrailer"); // needs to remain for stow from trailer - change location
  localStorage.removeItem("scanBatch"); // used in labelling screens
  localStorage.removeItem("scanMake"); // used in labelling screens
  localStorage.removeItem("currentPallet"); // used in pallet building and filling when scanning pallets.
}

function clearAuthorisation(username) {
  "use strict";
  var authorisation = localStorage.getObject("runtime/authorisation");
  authorisation.user = username;
  if(authorisation.transactionId) {
    //Only creates an authorisation transaction for scans which have a transaction.
    saveAuthorisationTransaction(authorisation,function(success) {
			// TOdo. Success not handled, so over capacity scanner could loose this transaction.
      localStorage.removeItem("runtime/authorisation");
        if(doesProgramSendTransactions())
          sendTransactions(false);
    });
  } else {
    localStorage.removeItem("runtime/authorisation");
  }
}

/**
 * @description Checks to see the users session is still valid, forcing a log out if not.
 * *Note that this is called before saving transactions. This is important as if the timeout function was to fire mid way, then LS could be corrupted.
 * @returns {boolean} returns true if valid, false if the timeout has reached.
 */
function isSessionValid() {
	"use strict";
	clearTimeout(sessionTimeout);
	if (isNumericAndNotZero(getSessionUserId())) {
    var timeNow = new Date().getTime(),
			timeout = parseInt(localStorage.getItem("settings/timeout")) * 60 * 1000;
		//Calculate time difference
		var timeDifference = timeNow - getActivityTime();
		if (timeDifference > timeout || isNaN(timeDifference)) {
			// Session has timed out
			logOut(true);
			return false;
		} else {
			// Update activity and set timer
			setActivityTime();
			sessionTimeout = setTimeout(function() {
				logOut(true);
			}, timeout);
			return true;
		}
	} else {
		// error with the user id
		logOut(false);
		return false;
	}
}

/**
 * @description Validates a user from localStorage. called from alert popups
 * @param {String} username
 * @param {String} pass
 * @param {string} adminKey
 * @returns {Null|String} Null when passed, an error string otherwise.
 */
function validateUser(username, pass, adminKey) {
  "use strict";
  var users = getScannerUsers();

  for (var i = 0; i < users.length; i++) if (users[i].login.toUpperCase() === username.toUpperCase()) {

    var hash = CryptoJS.SHA512(pass);
    var password = hash.toString(CryptoJS.enc.Hex);

    if (users[i].password === password) {
      // valid user
      //check security level
      var securityKey = "security/" + adminKey;
      var securityLevel = parseInt(localStorage.getItem(securityKey));
      return users[i].securityLevel >= securityLevel ? users[i].id : "This user is not authorised to perform this action";
    }
    // invalid password
    return "The Users password is incorrect.";
  }
  // invalid user
  return "Invalid username";
}

/**
 * @description Clears session and redirects to login.
 * @param {Boolean} expired True if the session expired.
 */
function logOut(expired) {
  "use strict";
  clearSession(expired);
  document.location.href = './dslogin.php';
}

/**
 * @description Updates various data models on the scanner based on settings. This is a bit of a linear process,
 * and is likely to call itself several times
 * @param {String} currentState - The state the update is in, determines what to do next.
 */
function updateDataSources(currentState) {
	"use strict";
	// The login button element shown on login page defined below.
	var $login = $('#login'),
  menuPage = "./dsmenu.php";

	console.log("State: " + currentState);
	// Each state should run the appropriate checks to see if it should be done.
	// If the state finds it shouldn't run then it should call itself, with an updated state to move to the next state.
	// Each state if good will run a function to update the appropriate data set. The function MUST callback.
	// The callback function passed should then call this function, with the next state.
	// The below switch is a bit confusing but basically we end up going through each switch statement, checking to see
	// what to do, the calling this function with the next switch statement condition until we are done.
	switch (currentState) {
		case "settings":
			$login.html("Updating Data...");
			downloadStandingData(function(success) {
				if (success) {
				  if (getScannerLocationCode() === "") {
						// Go to the location page to select the location - new scanner setup!
						document.location.href = './dslocation.php';
					}	else {
						// Location code is already set, next stage
						updateDataSources("allocation");
					}
				} else {
					// Update of standing data was not successful
					// If we are the location page then we should go straight to menu
					if (window.location.pathname === "/dslocation.php") {
						updateDataSources("done");
					}
					$login.removeAttr("disabled");
					$login.html("Log In");
				}
			});
			break;
		case "allocation":
			// We always update allocations so we call this without further checks.
			$login.html("Updating Allocations...");
			updateAllocations(function() {
				updateDataSources("trailer");
			});
			break;
		case "trailer":
			// Not everyone uses trailers, so we check before deciding what to do.
			if (areTrailersInUse()) {
				// Update trailers
				$login.html("Updating Trailers...");
				downloadTrailerData(function() {
					updateDataSources("container");
				});
			} else {
				updateDataSources("container");
			}
			break;
		case "container":
			// Not everyone uses containers (wood Tacking, so we check this too.
			if (areContainersInUse()) {
				$login.html("Updating Containers...");
				updateDownloadedContainers(function() {
					updateDataSources("woodDespatch");
				});
			} else {
				updateDataSources("woodDespatch");
			}
			break;
		case "woodDespatch":
			if (areDespatchesInUse()) {
				$login.html("Updating Despatches...");
				updateDownloadedDespatches(function() {
					updateDataSources("warehouseCapacity");
				});
			} else {
				updateDataSources("warehouseCapacity");
			}
			break;
		case "warehouseCapacity":
			updateCapacities($login, function() {
				updateDataSources("done");
			});
			break;
		case "done":
			document.location.href = menuPage;
			break;
		default:
			// If we ever hit this condition then something may have gone wrong.
			console.log("Updating data at login has encountered an error.");
			document.location.href = menuPage;
			break;
	}
}

/**
 * @description Login routine when logging in without network connectivity.
 * @param {String} username
 * @param {String} password
 */
function offlineLogin(username, password) {
  "use strict";
  var $login = $('#login');
  $login.html("Offline login...");
  console.log("Offline Login");

  var users = [],
      found = false;
  if (localStorage.getItem("data/users") !== null) {
    users = getScannerUsers();

    var timeResultFunction = function(correct) {
      if(!correct) {
        showIncorrectTimeMessage(function() {
          clearSession(false);
          $login.removeAttr("disabled");
          $login.html("Log In");
        });
      } else {
        offlineLoginCleanup();
        if (getScannerLocationCode() === "") {
          document.location.href = './dslocation.php';
        } else {
          document.location.href = './dsmenu.php';
        }
      }
    };

    for (var i = 0; i < users.length; i++) {
      if (users[i].login.toUpperCase() === username.toUpperCase()) {

        found = true;
        if (users[i].password === password) {

          createSession(users[i].id, username, users[i].coyno);
          $('a.usernamebutton').text(users[i].name);
          isScannerTimeCorrect(timeResultFunction);
        } else {
          showAlert("Offline login error: Incorrect Password.",{focus:"password"});
          $login.removeAttr("disabled");
          $login.html("Log In");
          return;
        }
      }
    }
    if(!found) {
      showAlert("Offline login error: The user does not exist.",{focus:"username"});
      $login.removeAttr("disabled");
      $login.html("Log In");
    }
  } else {
    showAlert("Offline login error: No users stored on the scanner.");
    $login.removeAttr("disabled");
    $login.html("Log In");
  }
}

/**
 * @description Returns the login of the current user.
 */
function getSessionUser() {
  "use strict";
	return sessionStorage.getVariable("runtime/username");
}

/**
 * @description Returns the User ID of the logged in user
 * @returns {Number}
 */
function getSessionUserId() {
	"use strict";
	return parseInt(sessionStorage.getVariable("runtime/userId"));
}

/**
 * @description Retrieves a users password.
 * @param {string} username
 * @returns {password|*|boolean}
 */
function getPassword(username) {
  "use strict";
  var users = getScannerUsers();
  for (var i = 0; i < users.length; i++) {
    if (users[i].login.toUpperCase() === username.toUpperCase()) {
      return users[i].password;
    }
  }
}

/**
 * @description Returns the default location of the user
 * @param {string} username - Login of the user
 * @returns {String} Default location code
 */
function getUsersDefaultLocation(username) {
	"use strict";
	var users = getScannerUsers();
	for (var i = 0; i < users.length; i++) {
		if (users[i].login.toUpperCase() === username.toUpperCase()) {
			return users[i].defaultLocation;
		}
	}
	return "";
}

/**
 * @description Indicates if the request may work after renewing the session with the server.
 * @returns {boolean} - True if the scanner should try a login. False if it already tried but failed.
 */
function canReattemptAfterLogin() {
  "use strict";
  return !applicationState.triedToRenewServerSession;
}

/**
 * @description Renews a server session with local information if a requests to the server gets a 401 error.
 * @param {successCallback} callback
 */
function renewServerSession(callback) {
  "use strict";
  var username = getSessionUser();
  var password = getPassword(username);
  var scannerid = (localStorage.getItem("scannerid")) ? localStorage.getItem("scannerid") : "0";
  var token = (localStorage.getItem("scannertoken")) ? localStorage.getItem("scannertoken") : "";
  var scannerlocation = getScannerLocationCode();
  var postdata = {
    username: username,
    password: password,
    application: "DRAMScan",
    scannerid: parseInt(scannerid),
    scannertoken: token,
    scannerlocation: scannerlocation
  };
  var poststring = JSON.stringify(postdata);
  applicationState.triedToRenewServerSession = true; // Set this to true as we are trying to renew the session with the server.

  /**
   * @param {configLoadedCallback}
   */
  getConfig(function(localConfig) {
    $.ajax({
      url: getRequestPath("session"),
      type: "POST",
      contentType: "application/json; charset=UTF-8",
      crossDomain: true,
      timeout:requestTimeout,
      xhrFields: {withCredentials: true},
      data: poststring,
      success: function (data, status, xhr) {

        console.log("Successful login request " + xhr.status);
        applicationState.triedToRenewServerSession = false; // we reset this as we logged in successfully. We might need to do this later again.
        callback(true);
      },
      error: function (xhr, status, thrown) {
        console.log("Failed login request: " + thrown + " " + xhr.status);
        // we must be offline or a problem has occurred, lets attempt offline login.
        callback(false);
      }
    });
  });
}

/**
 * @description Login routine connecting to the server. Upon completion standing data is updated.
 * @param {String} username
 * @param {String} password
 */
function onlineLogin(username, password) {
  "use strict";
  // disable login button
  var $loginbutton = $("#login");
  $loginbutton.html("Log in online...");

  var hash = CryptoJS.SHA512(password);
  password = hash.toString(CryptoJS.enc.Hex);

  var scannerid = (localStorage.getItem("scannerid")) ? localStorage.getItem("scannerid") : "0";
  var token = (localStorage.getItem("scannertoken")) ? localStorage.getItem("scannertoken") : "";
  var scannerlocation = getScannerLocationCode();
  var postdata = {
    username: username,
    password: password,
    application: "DRAMScan",
    scannerid: parseInt(scannerid),
    scannertoken: token,
    scannerlocation: scannerlocation
  };
  var poststring = JSON.stringify(postdata);

  $.ajax({
    url: getRequestPath("session"),
    type: "POST",
    contentType: "application/json; charset=UTF-8",
    crossDomain: true,
    xhrFields: {withCredentials: true},
    data: poststring,
    success: function (data, status, xhr) {

      console.log("Successful login request");
      var response = data;
      if (xhr.status === 201) {
        console.log("New scanner.");

				localStorage.setVariable("settings/scannerCompany",response.coyno, false);

				//todo the three below need renamed at some stage.
        localStorage.setVariable("scannerid", response.scannerid, false);
        localStorage.setVariable("scannertoken", response.scannertoken, false);

				// TODO we need to fix all these properties to come from the server differently
				// Below is a workaround causing issues on first log in.
				// Plan is to change the API to accept request for data with a blank location.
				if (response.location === "") {
					sessionStorage.setItem("tempLocation", response.scannerlocation);
				} else {
					sessionStorage.setItem("tempLocation", response.location);
				}

      }
			//Double check.
			if (!localStorage.getItem("settings/scannerCompany")) {
				localStorage.setVariable("settings/scannerCompany",response.coyno, false);
			}

      createSession(response.userid, username, response.coyno);
			updateDataSources("settings");
    },
    error: function (xhr, status, thrown) {
      console.log("Failed login request: " + thrown + " " + status);
      //alert(JSON.parse(xhr.responseText).message);
      // we must be offline or a problem has occured, lets attempt offline login.
      $loginbutton.removeAttr("disabled");
      $loginbutton.html("Log In");
      if (xhr.status === 400 || xhr.status === 401) {
        var responseMessage = JSON.parse(xhr.responseText).message;
        showAlert(responseMessage);
      } else {
        offlineLogin(username, password);
      }
    }
  });
}

/**
 * @typedef {object} userProfile
 * @property {number} id - The users unique ID
 * @property {string} login - A unique login name for the user
 * @property {string} username - The users name
 * @property {string} password - A hashed password for the user to use offline
 * @property {number} coyno - The users company number
 * @property {String} defaultLocation - The default location for the user to be restricted too
 * @property {number} securityLevel - The users security level for restricted access functions.
 * @property {Array<program>} menu - The users menu. If missing, then they do not have one assigned.
 *
 **/